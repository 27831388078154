<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center px-3 py-2">
          <div class="d-flex align-items-center title">You got a Gift!</div>
          <div />
          <div>
            <ion-buttons slot="end">
              <ion-button @click="dismissModal">
                <i class="ti-close" />
              </ion-button>
            </ion-buttons>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>
    <div class="modal-inner-content p-3">
      <ChLoading size="lg" v-if="isLoading" class="spinner mx-auto" />
      <div v-else class="d-flex justify-content-center align-items-center mx-auto flex-column">
        <div class="d-flex align-items-center">
          <img v-user-image class="prf-pic mx-1" :src="get(user, 'profile_picture_url')" />
          <span class="">
            <router-link :to="{ name: 'profile', params: { username: get(user, 'username') } }">
              @{{ get(user, 'username') }}<strong class="text-black"> sent you a gift!</strong>
            </router-link>
          </span>
        </div>
        <VirtualItemsDisplay :item="itemData" />
      </div>
    </div>

    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-button
            @click="dismissModal"
            class="thanks-btn m-2"
            :disabled="!isEmpty(gift) && !isEmpty(get(gift, 'extra.response'))"
            style="color: white !important"
          >
            <div class="d-flex align-items-center" style="color: white !important">
              <ion-icon class="icon mx-1" :icon="heartOutline" />
              Say Thanks
            </div>
          </ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import VirtualItemsDisplay from '@/shared/pages/store/VirtualItemsDisplay.vue';
import { heartOutline } from 'ionicons/icons';
import { getGiftRespondStatus, getVirtualItemDetails } from '~/shared/actions/virtualItems';
const props = defineProps({
  isOpen: { type: Boolean, default: false, required: true },
  user: { type: Object, default: {} },
});
const route = useRoute();
const emits = defineEmits(['close', 'open']);
const isOpen = toRef(props, 'isOpen');
const user = toRef(props, 'user');
const itemData = ref({});
const isLoading = ref(true);
const gift = ref({});
const id = ref(get(route.query, 'id') as string);

const fetchGiftDetails = async () => {
  const resp = await getGiftRespondStatus(id.value);
  gift.value = resp;
};
watch(isOpen, () => {
  if (isOpen.value && !isEmpty(id.value)) {
    fetchGiftDetails();
  }
});

const dismissModal = (val: any) => {
  !isEmpty(gift.value) && isEmpty(get(gift.value, 'extra.response')) ? emits('open', val) : emits('close');
};
watch(user, () => {
  if (user.value && isOpen.value) {
    fetchVirtualItemsDetails();
  }
});
const fetchVirtualItemsDetails = async () => {
  const resp = await getVirtualItemDetails(route.query.itemId);
  itemData.value = resp;
  isLoading.value = false;
};
</script>
<style lang="sass" scoped>
.thanks-btn
  background: #AE38E5
  --background: #AE38E5
  border-radius: 10px

.prf-pic
  height: 30px
  width: 30px
  object-fit: cover
  border-radius: 20px
.cb-text
  font-size: 18px
  margin-left: 0.25rem

.dark .toolbar
  --background: #17074c !important

.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: 70vh
  overflow: auto
  max-height: 70dvh

.modal-big
  --width: 700px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.title
  color: #214163
  font-size: 20px
</style>
